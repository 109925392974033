.iframe_container{
    width: 100%;
    min-height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* background: #0F1014; */
    background: #fff;
    background: #FAFAFA;
    padding: 20px;
}

.frame{
    width: 433px;
    height: 867px;
    position: relative;
}

.phone{
    width: 433px;
    height: 867px;
    object-fit: cover;
    /* background: #0F1014; */
    /* background: #fff; */
    padding: 10px;
    z-index: 3;
}

.notch{
    position: absolute;
    top: 33px;
    left: 155px;
}

.iframe{
    position: absolute;
    top: 35px;
    left: 35px;
    right:25px;
    width: calc(100% - 20px - 20px - 24px);
    height: calc(100% - 40px - 33px) !important;
    max-height:  calc(100% - 40px - 33px) !important;
    overflow: auto;
    border-radius: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    clip-path: polygon(0 0, 95% 0, 95% 100%, 0 100%);
}

/* .iframe_container_mobile{
    width: 100%;
    min-height: 100dvh;
}

.iframe_mobile{
    width: 100%;
    min-height: 100dvh;
} */


@media screen and (max-width:400px){
    .iframe_container{
        padding: 0px;
    }
    .frame{
        width: 100%;
        height: 100dvh;
        min-height:100dvh;
        max-height: 100dvh;
        position: relative;
    }
    .iframe{
        position: absolute;
        top: 0px;
        left: 0px;
        right:unset;
        width: 105%;
        height: 100dvh;
        min-height: 100dvh;
        max-height: 100dvh !important;
        overflow: auto;
        border-radius: unset;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
    }
    .phone{
        display: none;
    }
    
    .notch{
        display: none;
    }
}