.drawer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
    z-index: 1000;
    cursor: pointer;
}

.drawer_sub_container{
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0 0 0 4px #fafafa,0 10px 20px rgba(0,0,0,0.2);
    width: 200px;
    position: relative;
    min-height: 100dvh;
    max-height: 100dvh;
    padding: 10dvh 20px 5% 20px;
    overflow-y: auto;
}

.logo_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.logo{
    width: 80px;
    height: 60px;
    object-fit: contain;
}

.brand_name{
    color: #5f6368;
    font-family: 'Ubuntu',sans-serif;
    font-size: 24px;
}

.drawer_content{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.new_chat_btn,.faq_btn,.recent_btn{
    width: 100%;
    background:#F0F4F9;
    padding: 13px 10px 13px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 14px;
    border-radius: 30px;
    border: none;
    outline: none;
    cursor: pointer;
}

.plus_icon,.faq_icon,.recent_icon{
    color: #000;
    font-size: 20px;
}

.text{
    font-size: 16px;
    font-family: 'Ubuntu',sans-serif;
    color: #333333;
    max-width: 120px;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.line_breaker{
    width: 100%;
    height: 1px;
    background: #3333331c;
}

.chat_box{
    width: 100%;
    padding: 10px;
    /* background: #F0F4F9; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
}

.chat_box.active{
   background: #D3E3FD;
       background: #F0F4F9;
}

.chat_box_text{
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
    color: #333333;
    max-width: 120px;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}