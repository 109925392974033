.category_item{
    max-width: 250px;
    min-width: 250px;
    min-height: 150px;
    max-height: 150px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 0 4px #F0F4F9;
    position: relative;
}

.category_item_image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.question_container{
    padding: 30px 10px 30px 10px;
    min-width: 250px;
    max-width: 250px;
    min-height: 80px;
    max-height: 120px;
    background: #f0f4f94c;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 4px;
    box-shadow: 0 0 0 4px #F0F4F9;
    overflow: hidden;
}

.question{
    color: #333333;
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
    text-align: left;
    max-height: 80px;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;
}

.category_card_item{
    min-width: 250px;
    max-width: 250px;
    min-height: 150px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 0 4px #F0F4F9;
}

.category_card_item.cartoon{
    min-height: 200px;
}

.category_card_item_image_container{
    position: relative;
    width: 100%;
    min-height: 150px;
    max-height: 150px;
}

.category_card_item_image{
    width: 100%;
    height: 100%;
    min-height: 150px;
    max-height: 150px;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: 0 0 0 4px #F0F4F9;
}

.play_btn{
    position: absolute;
    bottom: 2px;
    right: 2px;
    min-height: 30px;
    max-height: 30px;
    background: #333333a5;
    backdrop-filter: blur(10px);
    border: none;
    outline: none;
    font-size: 14px;
    padding: 8px 10px 8px 10px;
    font-family: 'Ubuntu',sans-serif;
    color: #FFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px #f0f4f972;
}

.category_card_content{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 6px;
    padding: 8px;
    padding: 12px 8px 4px 8px;
}

.name{
    color: #333;
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
    max-width: 160px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.duration{
    color: #3d3d3d;
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
}