.modal_overlay{
    position: fixed;
    width: 100%;
    min-height: 100dvh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff8b;
    backdrop-filter: blur(4px);
    z-index: 99;
    cursor: pointer;
}

.modal{
    width: 350px;
    min-height: 300px;
    max-height: 350px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 4px #F0F4F9;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.modal_header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.animation{
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.header_text{
    /* color: #333; */
    font-size: 16px;
    font-weight: 600;
    font-family: 'Ubuntu',sans-serif;
    background: #333333;
    background: linear-gradient(to bottom right, #333333 20%, #CC2936 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 2px 20px rgba(0,0,0,0.1);
}

.message_wrapper{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.message_box{
    width: 100%;
    border-radius: 40px;
    resize: none; 
    background: #F0F4F9;
    min-height: 60px;
    max-height: 60px;
    border: none;
    outline: none;
    padding: 20px 100px 20px 30px;
    font-size: 18px;
    font-family: 'Ubuntu',sans-serif;
}

.message_box::-webkit-scrollbar {
    width: 0px; 
}
.message_box::-webkit-scrollbar-thumb {
    background-color: #ffffff00;
    border-radius: 0px;
}

.arrow_wrapper{
    position: absolute;
    border: none;
    outline: none;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 120;
    width: 40px;
    height:40px;
    /* background: #000; */
    background: #00000022;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:not-allowed;
    transition: all 0.3s;
}

.arrow_wrapper.active{
    background: #000;
    cursor: pointer;
}

.arrow_up_icon{
    color: #fff;
    font-size: 20px;
}

.message_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80%;
    min-height: 100px;
    background: #cc293744;
    box-shadow: 0 0 0 1px #cc2937a2;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    font-family: 'Ubuntu',sans-serif;
}