.carousel_section{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap:20px;
    width: 100%;
    overflow-x: hidden;
}

.carousel{
    width: 100%;
}

.carousel_item{
    width: 100%;
    height: auto;
    background: #f0f4f990;
    overflow: hidden;
    border-radius: 10px;
    padding: 6px;
    box-shadow: inset 0 0 0 4px #F0F4F9;
    cursor: grab;
}

.carousel_image{
    width: 100%;
    height: auto;
    position: relative;
}

.image_bg{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(to bottom,#00000036,#00000075);
}

.play_btn{
    position: absolute;
    bottom: 4px;
    right: 2px;
    min-height: 30px;
    max-height: 30px;
    background: #333333a5;
    backdrop-filter: blur(10px);
    border: none;
    outline: none;
    font-size: 14px;
    padding: 8px 10px 8px 10px;
    font-family: 'Ubuntu',sans-serif;
    color: #FFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px #f0f4f972;
}

.image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.carousel_content{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 6px;
    padding: 8px;
}

.name{
    color: #333;
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.duration{
    color: #3d3d3d;
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
}

@media screen and (max-width:500px){
    .play_btn{
        font-size: 12px;
    }
}