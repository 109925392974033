.category_main_container{
    position: relative;
    width: 100%;
    /* Nav 80px height - Main container 10px height */
    min-height: calc(100dvh - 10px - 80px);
    background: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px 30px 20px;
}

.video_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background: #000;
    overflow: hidden;
}

.video_player{
    width: 100%;
    height: auto;
    object-fit: contain;
    background: #000;
}

.iframe_container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.frame{
    height: 100dvh;
    width: 100%;
    background-color: #000;
    z-index: 1;
}

.exit_btn{
    position: absolute;
    top: 0;
    left: 0;
    min-width: 70px;
    max-width: 150px;
    padding: 10px;
    border: none;
    outline: none;
    background: rgba(240, 244, 249,0.5);
    color: #fff;
    font-size: 16px;
    font-family: 'Ubuntu',sans-serif;
    backdrop-filter: blur(10px);
    z-index: 2;
}
.exit_arrow,.expand_icon{
    font-size: 16px;
    color: #fff;
}

.full_screen_btn{
    position: absolute;
    top: 0;
    right: 0;
    min-width: 70px;
    max-width: 150px;
    padding: 10px;
    border: none;
    outline: none;
    background: rgba(240, 244, 249,0.5);
    color: #fff;
    font-size: 16px;
    font-family: 'Ubuntu',sans-serif;
    backdrop-filter: blur(10px);
    z-index: 2;
}

.exitFullScreenButton{
    position: absolute;
    top: 50px;
    right: 10px;
    min-width: 70px;
    max-width: 150px;
    padding: 10px;
    border: none;
    outline: none;
    background: rgba(240, 244, 249, 0.5);
    background: #333;
    color: #fff;
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;
    backdrop-filter: blur(10px);
    z-index: 1000 !important;
    cursor: pointer;
}

@media screen and (max-width:750px){
    .category_main_container{
        padding: 30px 10px 30px 10px;
    }
}