.modal_overlay{
    position: fixed;
    width: 100%;
    min-height: 100dvh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff8b;
    backdrop-filter: blur(4px);
    z-index: 99;
    cursor: pointer;
}

.modal{
    width: 350px;
    min-height: 300px;
    max-height: 350px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 4px #F0F4F9;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 20px 10px 20px 10px;
}

.form{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
}

.input{
    width: 100%;
}
.textField{
    width: 100% !important;
    min-height: 100px;
    max-height: 100px;
    overflow-y: auto !important;
}

.file{
    width: 100%;
    display: none;
}

.select_image{
    width: 100%;
    min-height: 70px;
    max-height: 70px;
    border: none;
    outline: none;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 14px;
}

.wrapper{
    padding: 10px;
    background: #6B7280;
    color: #fff;
    border-radius: 4px;
    font-size: 17px;
    font-family: sans-serif;
    text-align: left;
}

.blog_image{
    width: 80px;
    height: 50px;
    object-fit: contain;
}

.submit_btn{
    border: none;
    outline: none;
    width: 100%;
    padding: 10px;
    background: #0f58ce;
    color: #fff;
    border-radius: 4px;
    font-size: 18px;
    font-family: 'ubuntu',sans-serif;
    box-shadow: 0 0 0 2px #3163d9;
}