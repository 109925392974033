.category_blogs{
    width: 100%;
    display: grid;
    align-items: stretch;
    align-content: stretch;
    grid-template-columns: repeat(8,1fr);
    gap: 20px;
    cursor: pointer;
}

.blog{
    max-width: 250px;
    min-width: 250px;
    min-height: 300px;
    max-height: 300px;
    border-radius: 4px;
    background: #fff;
    overflow: hidden;
    box-shadow: 0 0 0 4px #F0F4F9;
    position: relative;
}

.blog_image_container{
    width: 100%;
    min-height: 140px;
    max-height: 140px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0 0 4px #F0F4F9;
    overflow: hidden;
}

.blog_image{
    width: 100%;
    height: 140px;
    object-fit: cover;
}

.blog_content{
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
}

.blog_title{
    color: #333333;
    font-size: 14px;
    font-weight: 800;
    font-family: 'Ubuntu',sans-serif;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;
}

.blog_snippet{
    color: #333333;
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;
}

.actions{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: absolute;
    bottom: 0;
}

.blog_details{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
}

.uploaded_by{
    color: #333;
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
}

.date_time{
    color: #474bff;
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
}

.link{
    border: none;
    outline: none;
    background: #474bff;
    border-radius: 2px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow_link{
    color: white;
    font-size: 14px;
}