.main_container{
    position: relative;
    width: 100%;
    /* Nav 80px height - Main container 10px height */
    min-height: calc(100dvh - 10px - 80px);
    background: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px 30px 20px;
}

.message_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
   
}

.message{
    width: 350px;
    min-height: 150px;
    background-color: #fff6f8;
    border: 1px solid #ffa7a0;
    border-left: 3px solid #f44336;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.message_text{
    color: #333;
    font-size: 20px;
    font-family: 'Ubuntu',sans-serif;
    font-weight: 600;
}

.back_btn{
    width: 100px;
    padding: 10px;
    border: none;
    outline: none;
    background: #f44336;
    color: #fff6f8;
    font-size: 18px;
    font-family: 'Ubuntu',sans-serif;
    border-radius: 24px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

@media screen and (max-width:750px){
    .main_container{
        padding: 30px 10px 30px 10px;
    }
}