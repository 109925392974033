.category_main_container{
    position: relative;
    width: 100%;
    /* Nav 80px height - Main container 10px height */
    min-height: calc(100dvh - 10px - 80px);
    background: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px 30px 20px;
}

@media screen and (max-width:750px){
    .category_main_container{
        padding: 30px 10px 30px 10px;
    }
}