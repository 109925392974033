*,*::before,*::after{
  box-sizing: border-box;
}

body{
  margin: 0;
  padding: 0;
  text-decoration: none;
  overflow-x: hidden;
}

p{
  margin: 0;
  padding: 0;
}
h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
}

button{
  cursor: pointer;
  transition: all 0.3s;
}

/* button:focus {
  scale: 1.2 !important;
} */

img{
  -webkit-user-drag: none; 
  user-drag: none;
}


.owl-nav{
  position: absolute;
  top: calc(-20px - 20px - 20px - 10px);
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.owl-carousel .owl-nav button.owl-prev,.owl-carousel .owl-nav button.owl-next{
  width: 40px;
  height:40px;
  border-radius: 50%;
  background-color: #333;
}

.owl-carousel .owl-nav button.owl-prev span{
  color: white !important;
  font-size: 20px !important;
  font-weight: 600px !important;
}

.nav-button.owl-prev,.nav-button.owl-next{
  background-color: transparent !important;
}

@media screen and (max-width:500px){
  .owl-nav{
    top: calc(-20px - 20px - 15px - 10px) !important;
  }
}