.top_menu_bar{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
}

.back_icon{
    color: #333;
    font-size: 20px;
}

.menu_buttons:nth-child(1){
    min-width: 50px;
    max-width: 50px;
}

.menu_buttons{
    min-width: 150px;
    max-width: 150px;
    padding: 10px;
    background:rgba(240, 244, 249,0.5);
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    font-family: 'Ubuntu',sans-serif;
    box-shadow: 0 0 0 4px rgba(240, 244, 249,1);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}