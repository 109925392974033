.main_container{
    position: relative;
    width: 100%;
    /* Nav 80px height - Main container 10px height */
    min-height: calc(100dvh - 10px - 80px);
    background: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px 30px 20px;
}

.blog_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.blog_title{
    color: #333;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Ubuntu',sans-serif;
}

.add_blog_btn{
    padding: 10px;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 6px;
    border: none;
    outline: none;
    text-wrap: nowrap;
    background: #474bff;
    border-radius: 2px;
    color: #ffffff;
    font-family: 'Ubuntu',sans-serif;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

@media screen and (max-width:750px){
    .main_container{
        padding: 30px 10px 30px 10px;
    }
}