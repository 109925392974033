.main_container{
    position: relative;
    width: 100%;
    /* Nav 80px height - Main container 10px height */
    min-height: calc(100dvh - 10px - 80px);
    background: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px 30px 20px;
}

.blog_header{
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.blog_title{
    color: #333;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Ubuntu',sans-serif;
}

.add_blog_btn{
    padding: 10px;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 6px;
    border: none;
    outline: none;
    text-wrap: nowrap;
    background: #474bff;
    border-radius: 2px;
    color: #ffffff;
    font-family: 'Ubuntu',sans-serif;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.main_blog_container{
    width: 100%;
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
    display: grid;
    grid-template-columns: 350px auto;
    gap: 20px;
}

.blog{
    max-width: 350px;
    min-width: 350px;
    width: 100%;
    height: fit-content;
    padding: 0 0 50px 0;
    border-radius: 4px;
    background: #fff;
    overflow: hidden;
    box-shadow: 0 0 0 4px #F0F4F9;
    position: relative;
}

.blog_image_container{
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0 0 4px #F0F4F9;
    overflow: hidden;
}

.blog_image{
    width: 100%;
    /* height: 150px; */
    height: 200px;
    object-fit: cover;
}

.blog_content{
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
}

.blog_title{
    color: #333333;
    font-size: 14px;
    font-weight: 800;
    font-family: 'Ubuntu',sans-serif;
    text-align: left;
    overflow: hidden;
}

.blog_snippet{
    color: #333333;
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
    text-align: left;
}

.actions{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: absolute;
    bottom: 0;
}

.blog_details{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
}

.uploaded_by{
    color: #333;
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
}

.date_time{
    color: #474bff;
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
}

.link{
    border: none;
    outline: none;
    background: #474bff;
    border-radius: 2px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow_link{
    color: white;
    font-size: 14px;
}

.other_blogs{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow: hidden;
}

.other_blogs_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    border-radius: 4px;
    padding: 0px 20px 0px 20px;
}

.other_blogs_heading{
    color: #333;
    font-size: 24px;
    font-family: 'Ubuntu',sans-serif;
    font-weight: 600;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
}

@media screen and (max-width:750px){
    .main_container{
        padding: 30px 10px 30px 10px;
    }
    .main_blog_container{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .other_blogs_container{
        padding: 0px 10px 0px 10px;
    }
    
}