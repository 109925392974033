.chat_container{
    position: relative;
    width: 100%;
    /* Nav 80px height - Main container 10px height */
    min-height: calc(100dvh - 10px - 80px);
    background: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.chat_input_output_container{
    width: 100%;
    /* Nav 80px height - Main container 10px Gap - 20px Chat Container Gap - 100px Message Box Height */
    min-height: calc(100dvh - 80px - 10px - 20px - 100px);
    max-height: calc(100dvh - 80px - 10px - 20px - 100px);
    padding: 30px 30px 30px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.main_intro_section{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.section_categories{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.loader_container{
    width: 100%;
    min-height: 150px;
    display: grid;
    place-content: center;
    place-items: center;
}

@media screen and (max-width:750px){
    .chat_input_output_container{
        padding: 30px 10px 30px 10px;
    }
}
