.chat_container{
    position: relative;
    width: 100%;
    /* Nav 80px height - Main container 10px height */
    min-height: calc(100dvh - 10px - 80px);
    background: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.chat_input_output_container{
    position: relative;
    width: 100%;
    /* Nav 80px height - Main container 10px Gap - 20px Chat Container Gap - 90px/140px Message Box Height */
    min-height: calc(100dvh - 80px - 10px - 20px - 100px);
    max-height: calc(100dvh - 80px - 10px - 20px - 100px);
    padding: 30px 30px 0px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.chat_input_output_container.adjustHeight{
    min-height: calc(100dvh - 80px - 10px - 20px - 160px);
    max-height: calc(100dvh - 80px - 10px - 20px - 160px);
}

.chat_container_chat_box:nth-child(odd){
    width: 60%;
    align-self: flex-end;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.chat_container_chat_box:nth-child(odd) .avatar_logo{
    width: 30px;
    height: 30px;
    align-self: flex-end;
}

.chat_container_chat_box:nth-child(odd) .avatar_logo .avatar{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.chat_container_chat_box:nth-child(odd) .chat_container_chat{
    width: 100%;
}

.chat_container_chat_box:nth-child(even){
    width: 60%;
    align-self: flex-start;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.chat_container_chat_box:nth-child(even) .avatar_logo{
    width: 30px;
    height: 30px;
    align-self: flex-start;
}

.chat_container_chat_box:nth-child(even) .avatar_logo .avatar{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.chat_container_chat_box:nth-child(even) .chat_container_chat{
    width: 100%;
}

.chat_container_chat_text{
    background: #F0F4F9;
    color: #333;
    font-family: 'Ubuntu',sans-serif;
    font-size: 16px;
    min-height: 50px;
    padding: 20px 10px 20px 10px;
    border-radius: 4px;
    overflow: auto;
}

.frame{
    width: 100%;
    min-height: 400px;
    max-height: 400px;
}

.info_container{
    width: 100%;
    padding: 20px;
    box-shadow: inset 0 0 2px #d82828;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;
    background: rgb(112, 5, 5);
}

.info_icon{
    color: #fff;
    font-size: 20px;
}

.info{
    color: #fff;
    font-family: 'Ubuntu',sans-serif;
    font-size: 16px;
}

@media screen and (max-width:1000px){
    .chat_container_chat_box:nth-child(odd){
        width: 90%;
    }
    .chat_container_chat_box:nth-child(even){
        width: 90%;
    }
}

@media screen and (max-width:750px){
    .chat_input_output_container{
        padding: 30px 10px 0px 10px;
    }
}
