.category_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.category_title_heading{
    text-align: left;
    /* color: #333; */
    font-family: 'Ubuntu',sans-serif;
    font-weight: 600;
    font-size: 32px;
    background: #000000;
    background: repeating-radial-gradient(circle farthest-corner at center center, #000000 0%, #333333 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media screen and (max-width:500px){
    .category_title_heading{
        font-size: 24px;
    }
    .explore_btn{
        padding: 8px;
        font-size: 12px;
        gap: 6px;
    }
    
}