.main_container{
    position: relative;
    width: 100%;
    /* Nav 80px height - Main container 10px height */
    min-height: calc(100dvh - 10px - 80px);
    background: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px 30px 20px;
}

.profile_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.avatar_image_container{
    width: 150px;
    height: 150px;
}

.avatar_image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.label{
    width: 300px;
    text-align: left;
    font-size: 24px;
    font-family: 'Ubuntu',sans-serif;
    font-weight: 600;
    color: #333;
}

.detail{
    width: 300px;
    text-align: left;
    font-size: 20px;
    font-family: 'Ubuntu',sans-serif;
    font-weight: 500;
    color: #E7ECED;
    background: #556080;
    padding: 10px;
    box-shadow: 0 0 0 4px #646f90;
    border-radius: 2px;
}

.log_out_btn{
    width: 300px;
    border: none;
    outline: none;
    background: #E02D2D;
    box-shadow: 0 0 0 4px #ee4e4e;
    padding: 10px;
    font-size: 20px;
    font-family: 'Ubuntu',sans-serif;
    font-weight: 500;
    color: #E7ECED;
    border-radius: 2px;
}

.your_blogs{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

@media screen and (max-width:750px){
    .main_container{
        padding: 30px 10px 30px 10px;
    }
}