.btn{
    padding: 10px;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 6px;
    border: none;
    outline: none;
    text-wrap: nowrap;
    background: #474bff;
    border-radius: 2px;
    color: #ffffff;
    font-family: 'Ubuntu',sans-serif;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.btn_icon{
    transform: rotateZ(-45deg);
    margin-top: -1px;
}

@media screen and (max-width:500px){
    .btn{
        padding: 8px;
        font-size: 12px;
        gap: 6px;
    }
}
