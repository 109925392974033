.main_container{
    position: relative;
    width: 100%;
    /* Nav 80px height - Main container 10px height */
    min-height: calc(100dvh - 10px - 80px);
    background: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px 30px 20px;
}

.recent_history_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.history_container{
    width: 100%;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 0 4px #F0F4F9;
    border-radius: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.bold{
    color: #333;
    font-size: 16px;
    font-weight: 600;
}

.chat_name,.date_time{
    color: #333;
    font-size: 16px;
    font-family: 'Ubuntu',sans-serif;
}

.open_btn{
    
}

@media screen and (max-width:750px){
    .main_container{
        padding: 30px 10px 30px 10px;
    }
}