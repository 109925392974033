.modal{
    position: fixed;
    top:50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%,-50%);
    min-height: 400px;
    max-height: 600px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 100px 100px rgba(0,0,0,0.1);
    z-index: 99;
}

.container{
    width: 100%;
    min-height: 400px;
    max-height: 600px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    overflow: visible;
}

.close_btn{
    z-index: 100;
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    background: #022763;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    box-shadow: 0 5px 20px rgba(0,0,0,0.1);
}

.close_icon{
    color: #fff;
    font-size: 20px;
}

.signin{
    width: 100%;
    height: 100%;
    padding: 30px 20px 30px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.signin_text{
    /* color: #333333; */
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    font-family: "Ubuntu",sans-serif;
    background: #333333;
    background: linear-gradient(to bottom right, #333333 20%, #0F58CE 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.input{
    width: 270px;
}

.btn{
    color: #fff;
    padding: 13px 10px 13px 10px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Ubuntu',sans-serif;
    background: #0F58CE;
}

.signup{
    width: 100%;
    height: 100%;
    padding: 30px 20px 30px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    background: #0F58CE;
    border-radius: 4px;
    box-shadow: 0 4px 4px 2px rgba(0,0,0,0.1);
}

.signup_header{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.text_primary{
    color: #fff;
    font-size: 24px;
    font-family: 'Pacifico',sans-serif;
    font-weight: 800;
    letter-spacing: 2px;
}

.text_secondary{
    color: #fafafa;
    font-size: 18px;
    font-family: 'Ubuntu',sans-serif;
    max-width: 80%;
    text-align: center;
    line-height: 20px;
}

.signup_btn{
    color: #333333;
    padding: 13px 10px 13px 10px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Ubuntu',sans-serif;
    background: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

@media screen and (max-width:1000px){
    .container{
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        min-height: 600px;
        max-height: 600px;
    }
}

@media screen and (max-width:500px){
  .input{
    width: 250px;
  }
  .signin,.signup{
    padding: 30px 5px 30px 5px;
  }
}